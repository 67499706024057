
import { defineComponent } from "vue";

const __default__ = defineComponent({
  name: "DefaultButton",
  props: {
    backgroundColor: {
      type: String,
      default: "#5a80e0",
    },
    color: {
      type: String,
      default: "#ffffff",
    },
    fontWeight: {
      type: String,
      default: "700",
    },
    borderColor: {
      type: String,
      default: "transparent",
    },
    borderWidth: {
      type: String,
      default: "0px",
    },
    marginBottom: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: true,
    },
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "0c48abe5": (_ctx.backgroundColor),
  "44d80f1a": (_ctx.color),
  "292fe497": (_ctx.fontWeight),
  "74eb43a7": (_ctx.borderColor),
  "7602420a": (_ctx.borderWidth),
  "40b5132e": (_ctx.marginBottom)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__