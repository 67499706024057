
import { defineComponent } from "vue";
import BaseHeader2 from "@/components/BaseHeader2.vue";
import DefaultButton from "@/components/common/DefaultButton.vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "SetPassword",
  components: { BaseHeader2, DefaultButton },
  data() {
    return {
      store: useStore(),
    };
  },
  methods: {
    signupEmail() {
      this.$pushGAEvent("sign_up_try", {
        method: "email",
      });
      this.$router.push("/signup");
    },
    loginKakao() {
      this.$pushGAEvent("sign_up_try", {
        method: "kakao",
      });
      this.$flutter.callHandler("loginKakao");
    },
  },
});
